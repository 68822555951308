import { template } from "@ember/template-compiler";
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import RewardsBadge from './badge';
export default class Rewards extends Component {
    getFilteredAndSortedBadges(acquisitionStatus1) {
        return this.args.badges.toArray().filter(({ isAcquired: isAcquired1 })=>isAcquired1 === acquisitionStatus1).sort((a1, b1)=>b1.isCertifiable - a1.isCertifiable);
    }
    get acquiredBadges() {
        return this.getFilteredAndSortedBadges(true);
    }
    get notAcquiredBadges() {
        return this.getFilteredAndSortedBadges(false);
    }
    static{
        template(`
    <h2 class="evaluation-results-tab__title">
      {{t "pages.skill-review.tabs.rewards.title"}}
    </h2>
    <p class="evaluation-results-tab__description">
      {{t "pages.skill-review.tabs.rewards.description"}}
    </p>
    {{#if this.acquiredBadges.length}}
      <h2 class="evaluation-results-tab__badges-title evaluation-results-tab__badges-title--acquired">
        <FaIcon @icon="circle-check" />
        {{t "pages.skill-review.badge-card.acquired"}}
      </h2>
      <ul class="evaluation-results-tab__badges-list">
        {{#each this.acquiredBadges as |badge|}}
          <RewardsBadge @badge={{badge}} />
        {{/each}}
      </ul>
    {{/if}}
    {{#if this.notAcquiredBadges.length}}
      <h2 class="evaluation-results-tab__badges-title evaluation-results-tab__badges-title--not-acquired">
        <FaIcon @icon="circle-xmark" />
        {{t "pages.skill-review.badge-card.not-acquired"}}
      </h2>
      <ul class="evaluation-results-tab__badges-list">
        {{#each this.notAcquiredBadges as |badge|}}
          <RewardsBadge @badge={{badge}} />
        {{/each}}
      </ul>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
