import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { pageTitle } from 'ember-page-title';
import didInsert from '../../modifiers/modifier-did-insert';
import BetaBanner from './beta-banner';
import ModuleGrain from './grain.js';
export default class ModulePassage extends Component {
    @service
    router;
    @service
    metrics;
    @service
    store;
    @service
    modulixAutoScroll;
    displayableGrains = this.args.module.grains.filter((grain1)=>ModuleGrain.getSupportedComponents(grain1).length > 0);
    @tracked
    grainsToDisplay = this.displayableGrains.length > 0 ? [
        this.displayableGrains[0]
    ] : [];
    @action
    hasGrainJustAppeared(index1) {
        if (this.grainsToDisplay.length === 1) {
            return false;
        }
        return this.grainsToDisplay.length - 1 === index1;
    }
    get hasNextGrain() {
        return this.grainsToDisplay.length < this.displayableGrains.length;
    }
    get currentGrainIndex() {
        return this.grainsToDisplay.length - 1;
    }
    @action
    onGrainSkip() {
        const currentGrain1 = this.displayableGrains[this.currentGrainIndex];
        this.addNextGrainToDisplay();
        this.metrics.add({
            event: 'custom-event',
            'pix-event-category': 'Modulix',
            'pix-event-action': `Passage du module : ${this.args.module.id}`,
            'pix-event-name': `Click sur le bouton passer du grain : ${currentGrain1.id}`
        });
    }
    @action
    onGrainContinue() {
        const currentGrain1 = this.displayableGrains[this.currentGrainIndex];
        this.addNextGrainToDisplay();
        this.metrics.add({
            event: 'custom-event',
            'pix-event-category': 'Modulix',
            'pix-event-action': `Passage du module : ${this.args.module.id}`,
            'pix-event-name': `Click sur le bouton continuer du grain : ${currentGrain1.id}`
        });
    }
    @action
    onStepperNextStep(currentStepPosition1) {
        const currentGrain1 = this.displayableGrains[this.currentGrainIndex];
        this.metrics.add({
            event: 'custom-event',
            'pix-event-category': 'Modulix',
            'pix-event-action': `Passage du module : ${this.args.module.id}`,
            'pix-event-name': `Click sur le bouton suivant de l'étape ${currentStepPosition1} du stepper dans le grain : ${currentGrain1.id}`
        });
    }
    addNextGrainToDisplay() {
        if (!this.hasNextGrain) {
            return;
        }
        const nextGrain1 = this.displayableGrains[this.currentGrainIndex + 1];
        this.grainsToDisplay = [
            ...this.grainsToDisplay,
            nextGrain1
        ];
    }
    @action
    grainCanMoveToNextGrain(index1) {
        return this.currentGrainIndex === index1 && this.hasNextGrain;
    }
    @action
    grainShouldDisplayTerminateButton(index1) {
        return this.currentGrainIndex === index1 && !this.hasNextGrain;
    }
    @action
    grainTransition(grainId1) {
        return this.args.module.transitionTexts.find((transition1)=>transition1.grainId === grainId1);
    }
    @action
    async onModuleTerminate({ grainId: grainId1 }) {
        const adapter1 = this.store.adapterFor('passage');
        await adapter1.terminate({
            passageId: this.args.passage.id
        });
        this.metrics.add({
            event: 'custom-event',
            'pix-event-category': 'Modulix',
            'pix-event-action': `Passage du module : ${this.args.module.id}`,
            'pix-event-name': `Click sur le bouton Terminer du grain : ${grainId1}`
        });
        return this.router.transitionTo('module.recap', this.args.module);
    }
    @action
    async onElementAnswer(answerData1) {
        await this.store.createRecord('element-answer', {
            userResponse: answerData1.userResponse,
            elementId: answerData1.element.id,
            passage: this.args.passage
        }).save({
            adapterOptions: {
                passageId: this.args.passage.id
            }
        });
        this.metrics.add({
            event: 'custom-event',
            'pix-event-category': 'Modulix',
            'pix-event-action': `Passage du module : ${this.args.module.id}`,
            'pix-event-name': `Click sur le bouton vérifier de l'élément : ${answerData1.element.id}`
        });
    }
    @action
    async onElementRetry(answerData1) {
        this.metrics.add({
            event: 'custom-event',
            'pix-event-category': 'Modulix',
            'pix-event-action': `Passage du module : ${this.args.module.id}`,
            'pix-event-name': `Click sur le bouton réessayer de l'élément : ${answerData1.element.id}`
        });
    }
    @action
    async onImageAlternativeTextOpen(imageElementId1) {
        this.metrics.add({
            event: 'custom-event',
            'pix-event-category': 'Modulix',
            'pix-event-action': `Passage du module : ${this.args.module.id}`,
            'pix-event-name': `Click sur le bouton alternative textuelle : ${imageElementId1}`
        });
    }
    @action
    async onVideoTranscriptionOpen(videoElementId1) {
        this.metrics.add({
            event: 'custom-event',
            'pix-event-category': 'Modulix',
            'pix-event-action': `Passage du module : ${this.args.module.id}`,
            'pix-event-name': `Click sur le bouton transcription : ${videoElementId1}`
        });
    }
    @action
    async onVideoPlay({ elementId: elementId1 }) {
        this.metrics.add({
            event: 'custom-event',
            'pix-event-category': 'Modulix',
            'pix-event-action': `Passage du module : ${this.args.module.id}`,
            'pix-event-name': `Click sur le bouton Play : ${elementId1}`
        });
    }
    @action
    async onFileDownload({ elementId: elementId1, downloadedFormat: downloadedFormat1 }) {
        this.metrics.add({
            event: 'custom-event',
            'pix-event-category': 'Modulix',
            'pix-event-action': `Passage du module : ${this.args.module.id}`,
            'pix-event-name': `Click sur le bouton Télécharger au format ${downloadedFormat1} de ${elementId1}`
        });
    }
    static{
        template(`
    {{pageTitle @module.title}}
    <BetaBanner />

    <main class="module-passage">
      <div class="module-passage__title">
        <h1>{{@module.title}}</h1>
      </div>

      <div
        class="module-passage__content"
        aria-live="assertive"
        {{didInsert this.modulixAutoScroll.setHTMLElementScrollOffsetCssProperty}}
      >
        {{#each this.grainsToDisplay as |grain index|}}
          <ModuleGrain
            @grain={{grain}}
            @onElementRetry={{this.onElementRetry}}
            @passage={{@passage}}
            @transition={{this.grainTransition grain.id}}
            @onImageAlternativeTextOpen={{this.onImageAlternativeTextOpen}}
            @onVideoTranscriptionOpen={{this.onVideoTranscriptionOpen}}
            @onElementAnswer={{this.onElementAnswer}}
            @onStepperNextStep={{this.onStepperNextStep}}
            @canMoveToNextGrain={{this.grainCanMoveToNextGrain index}}
            @onGrainContinue={{this.onGrainContinue}}
            @onGrainSkip={{this.onGrainSkip}}
            @shouldDisplayTerminateButton={{this.grainShouldDisplayTerminateButton index}}
            @onModuleTerminate={{this.onModuleTerminate}}
            @hasJustAppeared={{this.hasGrainJustAppeared index}}
            @onVideoPlay={{this.onVideoPlay}}
            @onFileDownload={{this.onFileDownload}}
          />
        {{/each}}
      </div>
    </main>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
