import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
export default template(`
  <h2 class="evaluation-results-tab__title">{{t "pages.skill-review.tabs.trainings.title"}}</h2>
  <p class="evaluation-results-tab__description">{{t "pages.skill-review.tabs.trainings.description"}}</p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
